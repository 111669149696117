import { types, getEnv } from 'mobx-state-tree';
import AuthStore from './AuthStore';
import UIStore from './UIStore';
import AppStore from './AppStore';
import BolsStore from './bol/BolsStore';

const RootStore = types
  .model('RootStore', {
    auth: types.optional(AuthStore, {}),
    ui: types.optional(UIStore, {}),
    app: types.optional(AppStore, {}),
    bols: types.optional(BolsStore, {}),
  })
  .views(self => ({
    get api() {
      return getEnv(self).api;
    },
  }))
  .actions(self => ({
    start() {
      !!self.auth.token && self.ui.loadLogo();
      self.auth.loadUser().then(() => self.app.setApploaded(true));
    },
  }))

export default RootStore;
