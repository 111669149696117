import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Table from '../../../ui/Table';
import PickupButton from './PickupButton';
import DeliverButton from './DeliverButton';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  leftBox: {
    marginBottom: theme.spacing(3),
  },
  leftBoxItem: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    position: 'relative',
    borderLeft: '1px dashed ' + theme.palette.secondary.main,
    paddingLeft: theme.spacing(3),
  },
  leftBoxActiveItem: {
    borderLeft: '1px solid ' + theme.palette.primary.main,
  },
  leftBoxItemTimeWrapper: {
    minWidth: '200px',
  },
  leftBoxItemTimeActions: {
    marginTop: theme.spacing(2),
  },
  leftBoxDot: {
    position: 'absolute',
    height: '15px',
    width: '15px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    top: '42px',
    left: '-8px',
  },
  leftBoxActiveDot: {
    backgroundColor: theme.palette.primary.main,
  },
  rightBox: {

  },
  rightBoxSubTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.secondary.main,
  },
  rightBoxContent: {
    marginTop: theme.spacing(1),
  },
  rightBoxContentBox: {
    minWidth: '400px',
    minHeight: '80px',
  },
  rightBoxContentTitle: {
    fontSize: '18px',
  },
  rightBoxContentText: {

  },
  rightBoxContentRemark: {
    border: '1px dashed ' + theme.palette.secondary.main,
    minHeight: '100px',
    borderRadius: '5px',
    marginTop: theme.spacing(1),
    padding: '11px 15px',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  loadsWrapper: {
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
  },
}));

const columns = [
  {
    Header: 'CTN QTY',
    accessor: 'qty',
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => parseFloat(row.values.qty) + sum, 0),
        [info.rows]
      )
      return total.toFixed(2);
    },
  },
  {
    Header: 'SHIPMENT ID',
    accessor: 'shipment_id',
    Footer: () => 'GRAND TOTAL',
  },
  {
    Header: 'REFERENCE ID',
    accessor: 'reference_id',
  },
  {
    Header: 'WEIGHT',
    accessor: 'weight_lb',
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => parseFloat(row.values.weight_lb) + sum, 0),
        [info.rows]
      )
      return `${total.toFixed(2)} LBS`;
    },
  },
  {
    Header: 'VOLUME',
    accessor: 'cbm',
    Footer: info => {
      const total = React.useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.cbm + sum, 0),
        [info.rows]
      )
      return `${total} CBM`;
    },
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'description',
  },
  {
    Header: 'CARTON MARKING',
    accessor: 'marking',
  },
  {
    Header: 'CNTR NUMBER',
    accessor: 'container_number',
  },
];

const Detail = observer(({ row }) => {
  const classes = useStyles();

  const { original } = row;

  return <Box className={classes.root}>
    <Grid container spacing={1} >
      <Grid item sm={12} md={6}>
        <Box className={classes.leftBox}>
          <Box className={clsx(classes.leftBoxItem, { [classes.leftBoxActiveItem]: !!original.confirmed_at })}>
            <Box className={clsx(classes.leftBoxDot, classes.leftBoxDot, { [classes.leftBoxActiveDot]: !!original.confirmed_at })} />
            <Box className={classes.leftBoxItemTimeWrapper}>
              <Box>Confirm BOL Time:</Box>
              <Box>{original.confirmed_at || '-'}</Box>
              <Box className={classes.leftBoxItemTimeActions}>
                <Button
                  size="small"
                  {...(original.confirmed_at ? {
                    variant: 'contained', color: 'primary',
                    onClick: original.unconfirm,
                  } : {
                      variant: 'outlined',
                      onClick: original.confirm,
                    })} >
                  Confirmed BOL
                </Button>
              </Box>
            </Box>
            <Box>
              <Box><strong>Carrier Name:</strong></Box>
              <Box>{original.carrier_name}</Box>
            </Box>
          </Box>

          <Box className={clsx(classes.leftBoxItem, { [classes.leftBoxActiveItem]: !!original.actual_pickup_time })}>
            <Box className={clsx(classes.leftBoxDot, classes.leftBoxDot, { [classes.leftBoxActiveDot]: !!original.actual_pickup_time })} />
            <Box className={classes.leftBoxItemTimeWrapper}>
              <Box>{original.actual_pickup_time ? '' : 'Schedule '}Pickup Time:</Box>
              <Box>{original.pickup_time || '-'}</Box>
              <Box className={classes.leftBoxItemTimeActions}>
                <PickupButton bol={original} />
              </Box>
            </Box>
            <Box>
              <Box><strong>Pickup Location:</strong></Box>
              <Box>
                <Typography variant="body2" component="pre">
                  {original.pickup_address}
                </Typography>
              </Box>
              <Box>REF#: {original.pickup_reference}</Box>
            </Box>
          </Box>

          <Box className={clsx(classes.leftBoxItem, { [classes.leftBoxActiveItem]: !!original.delivered_at })}>
            <Box className={clsx(classes.leftBoxDot, classes.leftBoxDot, { [classes.leftBoxActiveDot]: !!original.delivered_at })} />
            <Box className={classes.leftBoxItemTimeWrapper}>
              <Box>{original.delivered_at ? '' : 'Schedule '}Deliver Time:</Box>
              <Box>{original.delivery_time || '-'}</Box>
              <Box className={classes.leftBoxItemTimeActions}>
                <DeliverButton bol={original} />
              </Box>
            </Box>
            <Box>
              <Box><strong>Delivery Location:</strong></Box>
              <Box>
                <Typography variant="body2" component="pre">
                  {original.delivery_address}
                </Typography>
              </Box>
              <Box>REF#: {original.delivery_reference}</Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box className={classes.rightBox}>
          <Box className={classes.rightBoxSubTitle}>
            <Box mr={5}>Issue Date: {original.issued_at}</Box>
            <Box>Issue By: {original.issued_by}</Box>
          </Box>
          <Box className={classes.rightBoxContent}>
            <Box display="flex">
              <Box>
                <Box className={classes.rightBoxContentBox}>
                  <Box className={classes.rightBoxContentTitle}>Shipping Mode:</Box>
                  <Box className={classes.rightBoxContentText}>{original.shipping_mode}</Box>
                </Box>
                <Box className={classes.rightBoxContentBox}>
                  <Box className={classes.rightBoxContentTitle}>Payment Type:</Box>
                  <Box className={classes.rightBoxContentText}>{original.payment_type}</Box>
                </Box>
                <Box className={classes.rightBoxContentBox}>
                  <Box className={classes.rightBoxContentTitle}>Receiver Stamp/ Signature:</Box>
                  <Box className={classes.rightBoxContentText}></Box>
                </Box>
              </Box>
              <Box>
                <Box className={classes.rightBoxContentBox}>
                  <Box className={classes.rightBoxContentTitle}>Billing To:</Box>
                  <Box className={classes.rightBoxContentText}>
                    <Typography variant="body2" component="pre">
                      {original.billing_to}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box className={classes.rightBoxContentTitle}>Remark:</Box>
              <Box className={classes.rightBoxContentRemark}>
                <InputBase value={original.remark || ''} className={classes.input} onChange={e => original.setRemark(e.target.value)} multiline rows="4" onBlur={original.updateRemark} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>

    <Divider className={classes.divider} />

    <Box className={classes.loadsWrapper}>
      <Table
        columns={columns}
        data={original.loads.slice()}
        footerable
      />
    </Box>
  </Box>
})


export default Detail
