import React from 'react';
import { observer } from 'mobx-react';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const Sort = observer(({ bols }) => {
  return <Box display="flex" mt={2}>
    <Box mr={1}>
      <FormControl variant="outlined" size="small">
        <Select
          displayEmpty
          value={''}
        // onChange={handleChange}
        >
          <MenuItem value="">
            List by Location
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
    <Box mr={1}>
      <FormControl variant="outlined" size="small">
        <Select
          displayEmpty
          value={''}
        // onChange={handleChange}
        >
          <MenuItem value="">
            Placeholder
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  </Box>
})

export default Sort;
