import { types, getParent } from 'mobx-state-tree';

export const StatusCountItem = types
  .model({
    value: types.string,
    text: types.string,
    count: types.maybeNull(types.number),
  })
  .actions(self => ({
    isActive(status) {
      return self.value === status;
    },
    setCount(count) {
      self.count = count;
    },
  }));



export const BolRowLoad = types
  .model({
    id: types.number,
    uid: types.string,
    qty: types.number,
    shipment_id: types.maybeNull(types.string),
    reference_id: types.maybeNull(types.string),
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    cbm: types.maybeNull(types.union(types.number, types.string)),
    marking: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    container_number: types.maybeNull(types.string),
  });


export const BolRow = types
  .model({
    id: types.number,
    uid: types.string,
    status: types.maybeNull(types.string),
    carrier_name: types.maybeNull(types.string),
    confirmed_at: types.maybeNull(types.string),
    pickup_short_address: types.maybeNull(types.string),
    pickup_address: types.maybeNull(types.string),
    pickup_reference: types.maybeNull(types.string),
    scheduled_pickup_time: types.maybeNull(types.string),
    actual_pickup_time: types.maybeNull(types.string),
    delivery_short_address: types.maybeNull(types.string),
    delivery_address: types.maybeNull(types.string),
    delivery_reference: types.maybeNull(types.string),
    delivery_appointment_time: types.maybeNull(types.string),
    delivered_at: types.maybeNull(types.string),
    shipping_mode: types.maybeNull(types.string),
    payment_type: types.maybeNull(types.string),
    billing_to: types.maybeNull(types.string),
    remark: types.maybeNull(types.string),
    issued_at: types.maybeNull(types.string),
    issued_by: types.maybeNull(types.string),
    total_qty: types.maybeNull(types.union(types.number, types.string)),
    total_weight: types.maybeNull(types.union(types.number, types.string)),
    total_cbm: types.maybeNull(types.union(types.number, types.string)),
    loads: types.array(BolRowLoad),
  })
  .views(self => ({
    get pickup_time() {
      return self.actual_pickup_time || self.scheduled_pickup_time
    },
    get delivery_time() {
      return self.delivered_at || self.delivery_appointment_time
    },
  }))
  .actions(self => ({
    setRemark(remark) {
      self.remark = remark;
    },
    updateRemark() {
      getParent(self, 2).updateRemark(self.id, self.remark);
    },
    viewPDF() {
      getParent(self, 2).viewPDF(self);
    },
    printPDF() {
      getParent(self, 2).printPDF(self);
    },
    confirm() {
      return getParent(self, 2).confirm(self.id);
    },
    unconfirm() {
      return getParent(self, 2).unconfirm(self.id);
    },
    pickup(time) {
      return getParent(self, 2).pickup(self.id, time);
    },
    unpickup() {
      return getParent(self, 2).unpickup(self.id);
    },
    deliver(time) {
      return getParent(self, 2).deliver(self.id, time);
    },
    undeliver() {
      return getParent(self, 2).undeliver(self.id);
    },
  }))
