import React from 'react';
import Table from '../../../ui/Table';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Chip from '@material-ui/core/Chip';
import Detail from './Detail';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useStyles = makeStyles(theme => ({
  completedRow: {
    opacity: 0.5,
  },
}));

// let cell update when bol model changed
const Status = observer(({ bol }) => {
  return <Chip label={bol.status} />
});

const PickupInfo = observer(({ bol }) => {
  return <>
    <Box>{bol.pickup_short_address}</Box>
    <Box>{bol.pickup_time}</Box>
  </>;
});

const DeliveryInfo = observer(({ bol }) => {
  return <>
    <Box>{bol.delivery_short_address}</Box>
    <Box>{bol.delivery_time}</Box>
  </>;
});


const columns = [
  {
    Header: 'BOL ID',
    accessor: 'uid',
    Cell: ({ row }) => <Box  {...row.getToggleRowExpandedProps()}>{row.original.uid}</Box>,
  },
  {
    Header: 'STATUS',
    accessor: 'status',
    Cell: ({ row: { original } }) => <Status bol={original} />,
  },
  {
    Header: 'PICKUP INFO',
    accessor: 'pickup_short_address',
    Cell: ({ row: { original } }) => <PickupInfo bol={original} />,
  },
  {
    Header: 'DELIVERY INFO',
    accessor: 'delivery_short_address',
    Cell: ({ row: { original } }) => <DeliveryInfo bol={original} />,
  },
  {
    Header: 'SHIPPING MODE',
    accessor: 'shipping_mode',
  },
  {
    Header: 'CARGO INFO',
    accessor: 'total_qty',
    Cell: ({ row: { original } }) => <>
      <Box>{original.total_qty} Cartons</Box>
      <Box>{original.total_weight} LBS {original.total_cbm} CBM</Box>
    </>,
  },
  {
    // Make an expander cell
    Header: () => null, // No header
    id: 'expander', // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </span>
    ),
  },
];

const ListTable = observer(({ bols }) => {
  const classes = useStyles();

  const { collection } = bols;

  return <Table
    columns={columns}
    data={collection.slice()}
    getRowProps={({ original }) => {
      return original.is_pod ? { className: classes.completedRow } : {};
    }}
    renderRowSubComponent={(props) => <Detail {...props} />}
  />
})

export default ListTable;
