import request from './request';

export const bol = {
  counts() {
    return request.get('bols/counts');
  },
  index(params) {
    return request.get('bols', { params });
  },
  confirm(id) {
    return request.put(`bols/${id}/confirm`);
  },
  unconfirm(id) {
    return request.put(`bols/${id}/unconfirm`);
  },
  pickup(id, time) {
    return request.put(`bols/${id}/pickup`, { time });
  },
  unpickup(id) {
    return request.put(`bols/${id}/unpickup`);
  },
  deliver(id, time) {
    return request.put(`bols/${id}/deliver`, { time });
  },
  undeliver(id) {
    return request.put(`bols/${id}/undeliver`);
  },
  updateRemark(id, remark) {
    return request.put(`bols/${id}/updateRemark`, { remark });
  },
  show(id) {
    return request.get(`bols/${id}`);
  },
  viewPDF(id) {
    return this.exportPDF(id, false);
  },
  printPDF(id) {
    return this.exportPDF(id, true);
  },
  exportPDF(id, print) {
    return request.get(`bols/${id}/pdf`, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  pending() {
    return request.get('bols', { params: { pending: true } });
  },
  search(params) {
    return request.get('bols/search', { params });
  },
}
