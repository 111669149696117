import React from 'react';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: '18px',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
  },
  inputWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    border: '1px dashed #80889A',
    marginTop: '15px',
    padding: '11px 15px',
  },
  input: {
    flex: 1,
    width: '100%',
  },
}));

const Remark = observer(({ detail }) => {
  const classes = useStyles();

  return <Box>
    <Box className={classes.title}>Remark:</Box>
    <Box className={classes.inputWrapper}>
      <InputBase value={detail.remark || ''} className={classes.input} multiline rows="8" disabled />
    </Box>
  </Box>
})

export default Remark;
