export const BOL_LIST_STATUS_ALL = 'all';
export const BOL_LIST_STATUS_NEW = 'new';
export const BOL_LIST_STATUS_AWAITING_PICKUP= 'awaiting pickup';
export const BOL_LIST_STATUS_IN_TRANSIT = 'in transit';
export const BOL_LIST_STATUS_DELIVERED = 'delivered';
export const BOL_LIST_STATUS_CANCELED = 'canceled';

export const BOL_LIST_STATUSES_MAP = {
  [BOL_LIST_STATUS_ALL]: 'View All',
  [BOL_LIST_STATUS_NEW]: 'New',
  [BOL_LIST_STATUS_AWAITING_PICKUP]: 'Awaiting Pickup',
  [BOL_LIST_STATUS_IN_TRANSIT]: 'In Transit',
  [BOL_LIST_STATUS_DELIVERED]: 'Delivered',
  [BOL_LIST_STATUS_CANCELED]: 'Canceled',
};
