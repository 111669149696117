import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  item: {
    marginRight: theme.spacing(1),
  },
}));

const Header = observer(({ bols }) => {
  const classes = useStyles();

  const { status, setStatusAndLoad, statusCounts } = bols;

  return <Box className={classes.root}>
    {statusCounts.map(t => <Button key={t.value} className={classes.item} onClick={() => setStatusAndLoad(t.value)} color="primary" variant={t.isActive(status) ? 'contained' : 'outlined'} >
      {t.text}
    </Button>)}
  </Box>
})

export default Header;
