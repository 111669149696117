import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTable, useExpanded } from 'react-table'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    fontSize: '14px',
    lineHeight: 1.5,
    color: '#000000',
    borderCollapse: 'collapse',

    '& tr': {
      textAlign: props => props.textAlign,

      '& td, th': {
        margin: '0px 10px',
        padding: '15px 0',
        borderTop: '0.5px solid rgb(0, 0, 0, 0.2)',
        borderBottom: '0.5px solid rgb(0, 0, 0, 0.2)',
      },

      '& th': {
        opacity: 0.4,
        fontWeight: 500,
      },

      '& td': {
        fontWeight: 'normal',
      },
    },
  },
}));


// Create a default prop getter
const defaultPropGetter = () => ({})
const defaultGetSubRows = (row) => row.subRows || []


const Table = ({
  columns,
  data,
  getSubRows = defaultGetSubRows,
  getRowProps = defaultPropGetter,
  renderRowSubComponent,
  textAlign = 'left',
  footerable = false,
}) => {
  const classes = useStyles({ textAlign });

  const useTables = useTable(
    {
      columns,
      data,
      getSubRows,
    },
    useExpanded,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTables


  return <>
    <table {...getTableProps()} className={classes.root}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          const { key, rowProps } = row.getRowProps(getRowProps(row));

          return (
            // Merge user row props in
            <React.Fragment key={key}>
              <tr {...rowProps}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
              {row.isExpanded && renderRowSubComponent ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          )
        })}
      </tbody>
      {footerable && <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>}

    </table>
  </>
}


Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

export default Table;
