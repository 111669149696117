import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import SearchBar from './SearchBar';
import StatusBar from './StatusBar';
import Sort from './Sort';

const Header = observer(({ bols }) => {
  return <Box display="flex" mb={2}>
    <Box>
      <Box>
        <StatusBar bols={bols} />
      </Box>
      <Box><Sort /></Box>
    </Box>
    <Box ml="auto"></Box>
    <Box><SearchBar bols={bols} /></Box>
  </Box>
})

export default Header;
