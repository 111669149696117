import React from 'react';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

const PickupButton = observer(({ bol }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(format(new Date, 'yyyy-MM-dd'));

  const handleClick = (event) => {
    if (bol.actual_pickup_time) {
      bol.unpickup();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    bol.pickup(selectedDate).then(() => {
      if (bol.actual_pickup_time) {
        handleClose();
      }
    })
  }

  const open = Boolean(anchorEl);
  const id = open ? `${bol.id}-detail-pickup-button` : undefined;

  return <>
    <Button aria-describedby={id}
      size="small"
      onClick={handleClick}
      disabled={!bol.confirmed_at}
      {...(bol.actual_pickup_time ? { variant: 'contained', color: 'primary' } : { variant: 'outlined' })}
    >
      CONFIRMED PICKUP
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box px={3} py={1}>
        <form onSubmit={handleConfirm}>
          <Box>
            <TextField
              label="Confirm Pickup Time"
              type="date"
              value={selectedDate}
              onChange={e => setSelectedDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button type="submit" size="small" color="primary">CONFIRM</Button>
          </Box>
        </form>
      </Box>
    </Popover>
  </>
})


export default PickupButton;
