import { types, getParent, flow, applySnapshot } from 'mobx-state-tree';
import { BOL_LIST_STATUSES_MAP, BOL_LIST_STATUS_ALL } from './constants';
import { StatusCountItem, BolRow } from './models';
import { resolvePaginationStore } from '../PaginationStore';



const BolsStore = types
  .model('BolsStore', {
    query: '',
    status: types.optional(types.enumeration(Object.keys(BOL_LIST_STATUSES_MAP)), BOL_LIST_STATUS_ALL),
    statusCounts: types.frozen(Object.keys(BOL_LIST_STATUSES_MAP).map(t => StatusCountItem.create({ value: t, text: BOL_LIST_STATUSES_MAP[t] }))),
    collection: types.array(BolRow),
    pagination: types.optional(
      resolvePaginationStore({
        onLoadMore: (self) => getParent(self).loadMore(),
        onChangePerPage: (self) => getParent(self).loadCollection(),
      }),
      {
        perPage: 20
      },
    ),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    replaceCollection(data) {
      for (let row of self.collection) {
        if (row.id == data.id) {
          applySnapshot(row, data);
        }
      }
    },
    setStatus(status) {
      self.status = status;
    },
    setQuery(query) {
      self.query = query;
    },
    loadCounts: flow(function* () {
      try {
        const { data } = yield self.root.api.bol.counts()
        self.statusCounts.forEach(item => {
          item.setCount(data.data[item.value]);
        })
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadCollection: flow(function* ({ page = 1 } = {}) {
      try {
        const { data } = yield self.root.api.bol.index({ status: self.status, page: page, per_page: self.pagination.perPage, query: self.query });
        applySnapshot(self.collection, data.data);
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadMore: flow(function* () {
      try {
        const { data } = yield self.root.api.bol.index({ status: self.status, page: self.pagination.page, per_page: self.pagination.perPage, query: self.query });
        self.collection.push(...data.data);
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setStatusAndLoad(status) {
      self.pagination.reset();
      self.setStatus(status);
      self.loadCollection();
    },
    setAllStatusAndLoad() {
      self.pagination.reset();
      self.setStatus(BOL_LIST_STATUS_ALL);
      self.loadCollection();
    },
    viewPDF: flow(function* (id) {
      try {
        yield self.root.api.bol.viewPDF(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    printPDF: flow(function* (id) {
      try {
        yield self.root.api.bol.printPDF(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    confirm: flow(function* (id) {
      if (!window.confirm('Are you sure to Confirm')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.confirm(id);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    unconfirm: flow(function* (id) {
      if (!window.confirm('Are you sure to UnConfirm')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.unconfirm(id);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),

    pickup: flow(function* (id, time) {
      if (!window.confirm('Are you sure to Confirm Pickup')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.pickup(id, time);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    unpickup: flow(function* (id) {
      if (!window.confirm('Are you sure to UnConfirm Pickup')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.unpickup(id);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),

    deliver: flow(function* (id, time) {
      if (!window.confirm('Are you sure to Confirm Delivery')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.deliver(id, time);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    undeliver: flow(function* (id) {
      if (!window.confirm('Are you sure to UnConfirm Delivery')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.undeliver(id);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateRemark: flow(function* (id, remark) {
      try {
        self.loading = true;
        const response = yield self.root.api.bol.updateRemark(id, remark);
        self.replaceCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
  }));

export default BolsStore;
