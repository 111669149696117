import axios from "axios";
import FileSaver from 'file-saver';

const destroy = (target, targetId, documentId) => {
    return axios.delete(`documents/${documentId}/${target}/${targetId}`);
}

const store = (target, targetId, value) => {
    return axios.post(`documents/${target}/${targetId}`, value, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const documents = (target, targetId) => {
    return axios.get(`documents/${target}/${targetId}`);
}

const show = (target, targetId, document) => {
    return axios.get(`documents/${document.id}/${target}/${targetId}/url`)
        .then((response) => {
            const url = response.data;
            const w = window.open(url, '_blank');
        });
}

const download = (target, targetId, document) => {
    return axios.get(`documents/${document.id}/${target}/${targetId}`, { responseType: 'blob' })
        .then((response) => {
            // Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                {
                    type: response.headers['content-type'],
                }
            );
            FileSaver.saveAs(file, document.file_name);
        });
}




export const document = {
    destroy,
    store,
    documents,
    show,
    download,
}
