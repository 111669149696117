import { types, getRoot, applySnapshot, flow } from 'mobx-state-tree';
import { Document } from './models';
import { serialize } from 'object-to-formdata';

const DocumentsStore = types
  .model('DocumentsStore', {
    target: types.string,
    targetId: types.maybe(types.union(types.number, types.string)),
    collection: types.array(Document),
  })
  .volatile(self => ({
    loading: false,
    uploading: false,
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    setCollection(collection) {
      applySnapshot(self.collection, collection);
    },
    setTargetId(targetId) {
      self.targetId = targetId;
    },
    load: flow(function* (targetId) {
      self.targetId = targetId || self.targetId;
      self.loading = true;
      self.collection.clear();
      try {
        const response = yield self.root.api.document.documents(self.target, self.targetId);
        self.setCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    upload: flow(function* (documents) {
      self.uploading = true;
      try {
        const formData = serialize({ documents: [...documents] }, { indices: true, nullsAsUndefineds: true });
        const response = yield self.root.api.document.store(self.target, self.targetId, formData);
        self.setCollection(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.uploading = false;
    }),
    delete: flow(function* (documentId) {
      if (window.confirm('Are u sure to delete it?')) {
        self.loading = true;
        try {
          yield self.root.api.document.destroy(self.target, self.targetId, documentId);
          self.setCollection(self.collection.filter(d => d.id != documentId));
        } catch (error) {
          self.root.ui.toast.error(error);
        }
        self.loading = false;
      }
    }),
    view: flow(function* (document) {
      try {
        yield self.root.api.document.show(self.target, self.targetId, document);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    download: flow(function* (document) {
      try {
        yield self.root.api.document.download(self.target, self.targetId, document);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
  }))


export default DocumentsStore;
