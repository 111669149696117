import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Detail from './Detail';
import Footer from './Footer';
import Header from './Header';
import Memo from './Memo';
import { useParams } from "react-router-dom";

export default inject('root')(
  observer(({ root, bolId }) => {
    const { bol } = root;
    bolId = bolId ? bolId : useParams().bolId;

    useEffect(() => {
      bol.loadData(bolId);
      bol.whsDocuments.load(bolId);
      // bol.pandaDocuments.load(bolId);
    }, [])

    return <>
      <Header bol={bol} />
      <Detail bol={bol} />
      <Memo bol={bol} />
      <Footer bol={bol} />
    </>
  })
)
