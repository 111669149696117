import request from './request';

export const auth = {
  user() {
    return request.get('user');
  },
  login(email, password) {
    return request.post('login', { email, password })
  },
  logout() {
    return request.delete('logout');
  },
  logo() {
    return request.get('user/logo', { responseType: 'arraybuffer' });
  },
}
