import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { take } from 'lodash';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '-8px',
    backgroundColor: 'transparent',
    color: '#959597',
    fontSize: '12px',
    cursor: 'pointer',

    '&::before': {
      content: '"+"',
    },
  },
}))(Badge);

const defaultRowRender = row => row;

const CollectionText = observer(({ texts, taken = 2, classes = {}, rowRender = defaultRowRender, delimiter = ',' }) => {
  const t = take(texts, taken);

  const length = texts.length;
  const remainingLength = length - t.length;

  const renderedText = t.length === 0
    ? null
    : t
      .map(row => rowRender(row))
      .reduce((prev, curr) => [prev, delimiter, curr]);

  return <StyledBadge color="secondary" badgeContent={remainingLength} max={99}>
    <span className={classes.root}>{renderedText}</span>
  </StyledBadge>
})

CollectionText.propTypes = {
  texts: PropTypes.arrayOrObservableArray.isRequired,
  taken: ReactPropTypes.number,
}

export default CollectionText;
