import React from 'react';
import { observer } from 'mobx-react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const SearchBar = observer(({ bols }) => {
  const { query, setQuery, setAllStatusAndLoad } = bols;

  const handleSubmit = (e) => {
    e.preventDefault();
    setAllStatusAndLoad();
  }

  return <form onSubmit={handleSubmit}>
    <FormControl size="small" variant="outlined">
      <OutlinedInput
        type="text"
        value={query}
        onChange={e => setQuery(e.target.value)}
        labelWidth={0}
        placeholder="Search"
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              type="submit"
              onClick={handleSubmit}
              edge="start"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  </form>
})

export default SearchBar;
