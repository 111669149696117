import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import ShowMore from './ShowMore';
import Header from './Header';
import Table from './ListTable';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  showMore: {
    textAlign: 'center',
  },
}));


const List = inject('root')(
  observer(({ root }) => {
    const classes = useStyles();

    const { loadCounts, loadCollection } = root.bols;

    useEffect(() => {
      loadCounts();
      loadCollection();
    }, []);


    return <Box className={classes.root}>
      <Header bols={root.bols} />
      <Table bols={root.bols} />
      <Box mt={3} className={classes.showMore}>
        <ShowMore bols={root.bols} />
      </Box>
    </Box>
  })
)

export default List;
