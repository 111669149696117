import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FlagIcon, LeftArrowIcon } from '../../../ui/Icon';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  backIconWrapper: {
    marginRight: '35px',
    lineHeight: '40px',
  },
  backIcon: {
    fontSize: '24px',
    color: '#00000',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  titleWrapper: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#000000',
  },
  title: {
    fontSize: '18px',
  },
  subTitle: {
    fontSize: '9px',
    opacity: 0.5,
  },
  confirmed: {
    marginLeft: '30px',
  },
}));

const Header = observer(({ bol }) => {
  const classes = useStyles();
  const history = useHistory();

  const { detail } = bol;

  return <Box className={classes.root}>
    <Box className={classes.backIconWrapper}>
      <LeftArrowIcon className={classes.backIcon} onClick={() => history.goBack()} />
    </Box>
    <Box className={classes.titleWrapper}>
      <Box className={classes.title}>
        {detail.uid} / {detail.delivery_code} / {detail.total_qty}KGS
      </Box>
      <Box className={classes.subTitle}>
        # BOL / Destination Code / # Pieces
      </Box>
    </Box>
    <Box display="flex" alignItems="flex-end">
      {!!detail.actual_pickup_time && <Button onClick={detail.unpickup} className={classes.confirmed} size="small" variant="outlined" color="primary" startIcon={<FlagIcon />}>confirmed shipout</Button>}
    </Box>
    <Box display="flex" ml="auto">
      {!detail.actual_pickup_time && <Box>
        <Button variant="contained" color="primary" onClick={detail.pickup}>CONFIRM SIHPOUT</Button>
      </Box>}
      <Box ml={2}>
        <Button variant="outlined" color="primary" onClick={detail.viewPDF}>VIEW BOL</Button>
      </Box>
    </Box>
  </Box>
})

export default Header;
