import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '../../../ui/Table';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import CollectionText from '../../../common/CollectionText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '21px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '300px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '300px',
    },
  },
  subRows: {
    backgroundColor: '#FFFFFF',
  }
}));

const columns = [
  {
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleAllRowsExpandedProps()}
      >
        <Box>CNTR Number</Box>
        <Box>{isAllRowsExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>
      </Box>
    </>,
    accessor: 'container_number', // accessor is the "key" in the data
    Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps } }) => <>
      {canExpand ? <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleRowExpandedProps()}
      >
        <Box>{original.container_number}</Box>
        <Box width={24} ml={1}>{isExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>
      </Box> : null}
    </>,
  },
  {
    Header: 'Carton Marking',
    accessor: 'marking',
    Cell: ({ row: { original, depth } }) => <CollectionText texts={original.markings} />,
  },
  {
    Header: 'CNT QTY',
    accessor: 'qty',
  },
  {
    Header: 'Weight LB',
    accessor: 'weight_lb',
  },
  {
    Header: 'Pick Up Reference Number',
    accessor: 'pickup_reference',
  },
  {
    Header: 'MK Pallet Count',
    accessor: 'markup_pallet_count',
  },
];


const Detail = observer(({ bol }) => {
  const classes = useStyles();
  const { detail } = bol;

  return <Box className={classes.root}>
    <Table
      columns={columns}
      data={detail.items.slice()}
      getSubRows={(row) => row && row.loads ? row.loads.slice() : []}
      getRowProps={({ depth }) => {
        return depth > 0 ? { className: classes.subRows } : {};
      }}
    />
  </Box>
})

export default Detail;
