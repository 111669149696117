import React from 'react';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";


import Login from '../components/pages/Login';
import Home from '../components/pages/Home';

import BolList from '../components/pages/bol/List';
import BolDetail from '../components/pages/bol/Detail';




const Routes = ({ isAuthenticated }) => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return <>
    <Switch location={background || location}>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={Home} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/bols" component={BolList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/bols/:bolId" component={BolDetail} />

      <Route exact path="/login" component={Login} />

      <Route render={() => 'Not Found'} />
    </Switch>

    {background && <Switch>
    </Switch>}
  </>
}


const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component  {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

export default Routes;
