import React from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';


const ShowMore = observer(({ bols }) => {
  const { pagination, loading } = bols;
  const { total, page, lastPage, loadMore } = pagination;

  if (total === 0 || lastPage === page) {
    return null;
  }

  return <Button variant="outlined" color="primary" size="small" disabled={loading} onClick={loadMore}>Show More</Button>
})

export default ShowMore;
