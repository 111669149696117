import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { DocumentIcon } from '../../ui/Icon';
import { observer, PropTypes } from 'mobx-react';
import { useDropzone } from 'react-dropzone'
import filesize from 'filesize';
import Typography from '@material-ui/core/Typography';
import DocumentsStore from '../../../stores/document/DocumentsStore';
import CircularProgress from '@material-ui/core/CircularProgress';


const ACCEPT_FILE_TYPES = 'text/html,application/pdf,image/tiff,image/jpeg,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const MAX_SIZE = 10485760 // 10mb in bytes

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px dashed #80889A',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '27px 38px 23px 38px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  uploadBoxText: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#B7BBC4',
  },
  uploadBoxIconWrapper: {
    textAlign: 'center',
    marginTop: '8px',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#BBBFC8',
    lineHeight: '32px',
    display: 'inline-block',
    position: 'relative',
  },
  uploadBoxIcon: {
    fontSize: '22px',
    color: '#FFFFFF',
    verticalAlign: 'middle',
  },
  errorMsg: {

  },
  progress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  }
}));

const Dropzone = observer(({
  documents,
  maxSize = MAX_SIZE,
  accpetType = ACCEPT_FILE_TYPES,
}) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState(null);
  const { uploading, loading } = documents;

  const onDrop = () => {
    setErrorMsg(null);
  }

  const onDropAccepted = (uploadDocuments) => {
    documents.upload(uploadDocuments);
  }

  const onDropRejected = (documents) => {
    if (documents && documents.length > 0 && documents.filter(document => document.size > maxSize).length > 0) {
      setErrorMsg(`Document is too large (Max ${filesize(maxSize)})`)
    } else {
      setErrorMsg('Unsupported document type.')
    }
  }

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    onDropAccepted,
    onDropRejected,
    maxSize,
    accept: accpetType,
    minSize: 0,
    multiple: true,
  })

  return <Box className={classes.root} {...getRootProps()}>
    <input {...getInputProps()} disabled={uploading || loading} />
    <Box className={classes.uploadBoxText}>Click here or drag to upload file.</Box>
    <Box className={classes.uploadBoxIconWrapper}>
      <DocumentIcon className={classes.uploadBoxIcon} />
      {uploading && <CircularProgress size={48} className={classes.progress} />}
    </Box>
    {!!errorMsg && <Box><Typography variant="overline" color="error">{errorMsg}</Typography></Box>}
  </Box>
})


Dropzone.prototype = {
  documents: PropTypes.observableObject(DocumentsStore),
}


export default Dropzone;
